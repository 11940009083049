import { Component, createRef } from "preact";
import { createPortal } from 'preact/compat';
import _, { initial } from 'lodash';
import * as helpers from "@cargo/common/helpers"
import { connect } from 'react-redux';
import register from "./register"
import { ADMIN_FRAME, FRONTEND_DATA } from "../../globals";
import selectors from '../../selectors';
import {APIProvider, Map as GoogleMap, AdvancedMarker, useMap} from '@vis.gl/react-google-maps';
import { Easing, Tween, Group } from "@tweenjs/tween.js";

import { useEffect } from 'preact/hooks';

import { withPageInfo } from "./page-info-context";

import MapEditor from '../overlay/map-editor';
import content from "../content";
let resizeObserver;

let mapAnimationTimeout;

const easeInOutCubic = t => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

const setView = (map, targetPosition, targetZoom, options = {}) => {

    // Start by rounding the target zoom to the nearest whole number
    targetZoom = Math.round(targetZoom);

    const { duration = 1000, easing = easeInOutCubic } = options;

    const startTime = performance.now();
    const initialPosition = map.getCenter();
    const initialZoom = map.getZoom();

    const projection = map.getProjection();
    const targetLatLng = new google.maps.LatLng(targetPosition);
    const targetPoint = projection.fromLatLngToPoint(targetLatLng);
    const initialPoint = projection.fromLatLngToPoint(initialPosition);

    const zoomOffset = targetZoom - initialZoom;

    function interpolate(start, end, t) {
        return start + (end - start) * t;
    }

    function animate() {

        const now = performance.now();
        const elapsedTime = now - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = easing(progress);

        // Interpolate zoom
        const currentZoom = interpolate(initialZoom, targetZoom, easedProgress);

        const interpolatedPoint = new google.maps.Point(
            interpolate(initialPoint.x, targetPoint.x, easedProgress),
            interpolate(initialPoint.y, targetPoint.y, easedProgress)
        );

        let scale, scaledPoint;

        if (zoomOffset > 0) {
            // Calculate scale factor for current zoom level
            scale = Math.pow(2, Math.abs(currentZoom - initialZoom));
            scaledPoint = new google.maps.Point(
                targetPoint.x + (interpolatedPoint.x - targetPoint.x) / scale,
                targetPoint.y + (interpolatedPoint.y - targetPoint.y) / scale
            );
        }

        if (zoomOffset < 0) {
            // Calculate scale factor for current zoom level
            scale = Math.pow(2, Math.abs(currentZoom - targetZoom));
            scaledPoint = new google.maps.Point(
                initialPoint.x + (interpolatedPoint.x - initialPoint.x) / scale,
                initialPoint.y + (interpolatedPoint.y - initialPoint.y) / scale
            );
        }

        if (zoomOffset === 0) {
            const distance = Math.sqrt(Math.pow(targetLatLng.lat() - initialPosition.lat(), 2) + Math.pow(targetLatLng.lng() - initialPosition.lng(), 2));
            const scaledDistance = distance * Math.pow(2, currentZoom - initialZoom);
            if (scaledDistance < 4) {
                scaledPoint = interpolatedPoint;
            } else {
                // Skip the animation if the distance is too large
                scaledPoint = targetPoint;
            }
        }

        const currentLatLng = projection.fromPointToLatLng(scaledPoint);

        // Move the map's camera
        map.moveCamera({
            center: currentLatLng,
            zoom: currentZoom,
        });

        const offset = {
            x: targetPoint.x - scaledPoint.x,
            y: targetPoint.y - scaledPoint.y,
        }

        if ((zoomOffset !== 0 || offset.x !== 0 || offset.y !== 0) && progress < 1) {
            requestAnimationFrame(animate);
        }
    }

    animate();
}

const PinIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16,0C7.61,0,2.4,8.89,6.59,16L16,32l9.41-16C29.6,8.89,24.39,0,16,0z" fill="#FF2E00"/>
        </svg>
    )
}

const FlagIcon = () => {
    return (
        <svg width="58" height="50" viewBox="0 0 2 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.112305 0.460083L28.9258 14.1374L2.10889 24.6191V49.701H0.108887V22.9724H0.112305V0.460083Z" fill="#FF0026"/>
        </svg>

    )
}

const MapContextLoader = ({targetNode, activePurl}) => {
    // This component makes the Google Maps instance accessible on the map's baseNode via the map property. 
    const map = useMap();
    useEffect(() => {
        if (map) {
            // Add event listener to wait for map to be fully loaded
            google.maps.event.addListenerOnce(map, 'idle', () => {
                const followNavigation = targetNode.hasAttribute('follow-navigation') ? targetNode.getAttribute('follow-navigation') === 'true' : false;
                if (activePurl && followNavigation) {
                    const markersArr = targetNode.hasAttribute('markers') ? JSON.parse(decodeURIComponent(targetNode.getAttribute('markers'))) : [];
                    const targetMarker = markersArr.find(marker => marker.purl === activePurl);
                    if (targetMarker) {
                        map.moveCamera({
                            center: {lat: targetMarker.lat, lng: targetMarker.lng},
                            zoom: 17,
                        });
                    }
                }
            });
            targetNode.map = map;
        }
    }, [map]);
    return null;
}

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(entries => {

		entries.forEach(entry => {
			if( mapInstances.has(entry.target)){

				const component = mapInstances.get(entry.target);

				let box = entry.borderBoxSize[0] || entry.borderBoxSize;

				component.setState({
					size: {
						width: box.inlineSize,
						height: box.blockSize,
					}
				})

			}

		});
		
	});	

}

// Note: Map here is a javascript primitive, not creating an embedded Cargo Map
const mapInstances = new Map();

class CargoMap extends Component {

	constructor(props) {
		
		super(props);

		this.state = {
            mounted: false,
            tagText: null,
            isInCrossOriginIFrame: true,
            mapId: this.getMobileMapId(props['map-id']),
		}

        this.mapRef = createRef();

	}

    parseMarkersAttr (str) {
        return JSON.parse(decodeURIComponent(str));
    }

    encodeMarkersAttr (obj) {
        return '';
    }

	render(props, state) {
		const {
			baseNode,
			adminMode,
            isEditing,
			pageInfo,
            // 'api-key': apiKey,
            markers: markersAttr,
            width = '100%',
            height = '50vh',
            'map-id': mapId = '6f83276caebd115a',
            isMobile,
            'default-bounds': defaultBounds,
            'show-zoom-controls': showZoomControls = false,
            'show-street-view-controls': showStreetViewControls = false,
            'show-map-type-controls': showMapTypeControls = false,
            'follow-navigation': followNavigation = false,
		} = props;

        const apiKey = 'AIzaSyAuHt0V0nJ5qTiUoWwsOwDfkTv0n1PJUNA';

        const defaultBoundsObj = defaultBounds ? JSON.parse(decodeURIComponent(defaultBounds)) : {
            "ne": {
                "lat": 34.199298711595,
                "lng": -118.20583547095032
            },
            "sw": {
                "lat": 34.0103546000496,
                "lng": -118.33143248997767
            }
        }

        let markersArr = [];

        if (markersAttr) {
            markersArr = this.parseMarkersAttr(markersAttr);
        }

        // If tag text is applied and map has Follow Navigation enabled, filter the markers by tag text
        if (this.state.tagText !== null && followNavigation === true) {
            const tagsArr = this.state.tagText.split(',');
            // Get an array of marker purls that match the tag text
            const purls = tagsArr.reduce((acc, curr) => {
                const purlsByTag = this.props.purlsByTag[curr];
                if (!purlsByTag || purlsByTag.length === 0) {
                    return acc;
                }
                for (const purl of purlsByTag) {
                    if (acc.includes(purl)) {
                        continue;
                    } else {
                        acc.push(purl);
                    }
                }
                return acc;
            }, []);
            markersArr = markersArr.filter((marker) => purls.includes(marker.purl));
        }

		return createPortal(<>
				{adminMode && pageInfo.isEditing && <MapEditor
					mapInstance={baseNode}
				/> }
				<style>{`
					* {
				    	box-sizing: border-box;						
					}

					:host {
                        width: ${width};
                        height: ${height};
                        max-width: 100%;
                        max-height: var(--fit-height);
                        display: inline-block;
                        overflow: hidden;
					}

                    .gm-style iframe + div { border:none!important; }
				
					`}
				</style>
                {!apiKey ? (
                    <div style={{width: '100%', height: '100%', background: 'gray', color: 'red', fontSize: '24px'}}>API key is required for Google Maps</div>
                ) : (
                    <APIProvider apiKey={apiKey}>
                        <GoogleMap 
                            defaultBounds={defaultBoundsObj}
                            mapId={this.state.mapId}
                            ref={this.mapRef}
                            fullscreenControl={false}
                            gestureHandling="greedy"
                            streetViewControl={showStreetViewControls === 'true' || showStreetViewControls === true ? true : false}
                            mapTypeControl={showMapTypeControls === 'true' || showMapTypeControls === true ? true : false}
                            zoomControl={showZoomControls === 'true' || showZoomControls === true ? true : false}
                            clickableIcons={false}
                            >
                            {markersArr && markersArr.length ? markersArr.map((marker, index) => {
                                if (!marker.lat || !marker.lng) {
                                    return null;
                                }
                                return (
                                    <AdvancedMarker
                                        key={index}
                                        className={`marker-${index} ${marker.id ? `mid-${marker.id}` : ''}`}
                                        draggable={adminMode && pageInfo.isEditing ? true : false}
                                        onClick={adminMode && pageInfo.isEditing ? (e) => {
                                            this.props.baseNode.dispatchEvent(new CustomEvent('marker-click', {
                                                detail: {
                                                    index,
                                                }
                                            }));
                                        } : adminMode === false ? () => {} : null}
                                        onDragEnd={(e) => {
                                            this.props.baseNode.dispatchEvent(new CustomEvent('marker-dragend', {
                                                detail: {
                                                    index,
                                                    lat: e.latLng.lat(),
                                                    lng: e.latLng.lng(),
                                                }
                                            }));
                                        }}
                                        position={{lat: marker.lat, lng: marker.lng}}
                                    >
                                        {adminMode || marker.purl === 'default' || marker.purl === '' ? (
                                            <FlagIcon/>
                                        ) : (
                                            <a href={`${marker.scrollToPage ? '#' : ''}${marker.purl}`} rel="history" style={{fontSize: '24px', lineHeight: 1, marginBottom: '-4px'}} onClick={() => {
                                                setView(this.props.baseNode.map, {lat: marker.lat, lng: marker.lng}, 17);
                                            }}>
                                                <FlagIcon/>
                                            </a>
                                        )}
                                    </AdvancedMarker>
                                );
                            }) : null}
                            <MapContextLoader targetNode={baseNode} activePurl={this.props.activePurl} />
                        </GoogleMap>
                    </APIProvider>
                )}
			</> , baseNode.shadowRoot)
		
	}

	disableMouseInteraction = ()=> {
		this.setState({
			mouseInteractionDisabled: true,
		})
	}

	enableMouseInteraction = ()=> {
		this.setState({
			mouseInteractionDisabled: false,
		})
	}

    getBoundsZoomLevel(bounds, mapDim) {
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 21;
    
        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }
    
        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }
    
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
    
        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    
        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
    
        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    
        return Math.min(latZoom, lngZoom, ZOOM_MAX);
    }

    resetMap = () => {
        const defaultBounds = this.props['default-bounds'] ? JSON.parse(decodeURIComponent(this.props['default-bounds'])) : {north: 34.24028483839939, east: -118.16102709664018, west: -118.32410540683625, south: 33.994992380212196, padding: 0};
        const bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(defaultBounds.south, defaultBounds.west),
            new google.maps.LatLng(defaultBounds.north, defaultBounds.east)
        );
        const zoom = this.getBoundsZoomLevel(bounds, {width: this.state.size.width, height: this.state.size.height});
        setView(this.props.baseNode.map, bounds.getCenter(), zoom);
    }

    handleGlobalClick = (e, originalTarget) => {
        if (!this.props.markers || this.props.adminMode === true) {
            return;
        }

        const markersArr = JSON.parse(decodeURIComponent(this.props.markers));

        if (originalTarget.getAttribute('rel') === 'map-marker') {
            const markerId = originalTarget.getAttribute('href');
            if (!markerId) {
                return;
            } else {
                const markerObj = markersArr.find(marker => marker.id === markerId);
                if (markerObj) {
                    setView(this.props.baseNode.map, {lat: markerObj.lat, lng: markerObj.lng}, 17);
                }
                return;
            }
        }

        const purl = originalTarget.getAttribute('href');
        if (!purl) {
            return;
        }

        if (originalTarget.hasAttribute('tag-text')) {
            this.setState({
                tagText: originalTarget.getAttribute('tag-text'),
            })
        } else {
            this.setState({
                tagText: null,
            })
        }

        // Check if any of the markers have the same purl
        let targetMarker = null;
        for (const marker of markersArr) {
            if (marker.purl === purl) {
                targetMarker = marker;
                break;
            }
        }

        if (targetMarker) {
            setView(this.props.baseNode.map, {lat: targetMarker.lat, lng: targetMarker.lng}, 17);
        } else {
            this.resetMap();
        }
        
        return;
	}

	componentDidMount(){
        if (helpers.isServer) {
            return;
        }
        // Seutp resize observer
        // Add the component to the mapInstances map
        mapInstances.set(this.props.baseNode, this);
        resizeObserver.observe(this.props.baseNode);
        // Set a timeout to allow the page to render before we calculate the height

		this.props.baseNode.handleGlobalClick = this.handleGlobalClick;

        let isInCrossOriginIFrame = false;
        try {
            isInCrossOriginIFrame = window.parent?.location?.host && window.location.host !== window.parent.location.host ? true : false;
        } catch (e) {
            isInCrossOriginIFrame = true;
        }

        this.setState({
            mounted: true,
            isInCrossOriginIFrame,
        })
	}

    getMobileMapId = (mapId = '6f83276caebd115a') => {
        console.log('getMobileMapId', mapId);
        const mapIdMobileLookup = {
            '6f83276caebd115a': '253a84a7bd441322',
            '88cce47d0e4397ad': 'ed1c36f3c0173c73',
        };
        return mapIdMobileLookup[mapId] || mapId;
    }

	componentDidUpdate(prevProps, prevState){
        if (prevState.mapId !== this.state.mapId) {
            console.log('Map ID changed', this.state.mapId);
        }
        if (prevProps.isMobile === this.props.isMobile && prevState.isInCrossOriginIFrame === this.state.isInCrossOriginIFrame) {
            return;
        }

        if (prevProps.isMobile !== this.props.isMobile) {
            if (this.props.isMobile) {
                this.setState({
                    mapId: this.getMobileMapId(this.props['map-id']),
                });
            } else {
                this.setState({
                    mapId: this.props['map-id'] || '6f83276caebd115a',
                });
            }
        }
        if (prevState.isInCrossOriginIFrame !== this.state.isInCrossOriginIFrame) {
            if (this.state.isInCrossOriginIFrame && mapIdMobileLookup[this.props['map-id']]) {
                this.setState({
                    mapId: this.getMobileMapId(this.props['map-id']),
                });
            } else {
                this.setState({
                    mapId: this.props['map-id'] || '6f83276caebd115a',
                });
            }
        }

	}

	componentWillUnmount(){
		if(!helpers.isServer){
            // Remove the component from the mapInstances map
            mapInstances.delete(this.props.baseNode);
            resizeObserver.unobserve(this.props.baseNode);
			// 	
		}		
	}


}


const ConnectedMap = withPageInfo(connect(
    (state, ownProps) => {
        const pagesById = state.pages.byId;
        const purls = ownProps.markers ? JSON.parse(decodeURIComponent(ownProps.markers)).map(marker => marker?.purl) : [];
        const pages = purls.map(purl => Object.values(pagesById).find(page => page.purl === purl));
        const purlsByTag = pages.reduce((acc, page) => {
            if (page && page.tags) {
                page.tags.forEach(tag => {
                    if (!acc[tag.url]) {
                        acc[tag.url] = [];
                    }
                    acc[tag.url].push(page.purl);
                });
            }
            return acc;
        }, {});

        const activePurl = state.pages.byId[state.frontendState.activePID]?.purl;

        return {
            purlsByTag,
            activePurl,
            adminMode: state.frontendState.adminMode,
            isMobile: state.frontendState.isMobile,
        };
    }
)(CargoMap));


register(ConnectedMap, 'cargo-map', [
	'api-key',
]) 

export default ConnectedMap;
